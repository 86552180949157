import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import LoginForm from "./components/LoginForm";
import ForgetPasswordForm from "./components/ForgetPasswordForm";
require('prerender-node').set('prerenderToken', 'wMADexqDXM0hV4dBCKik');

const AuthenticationForm = () => {
    const [isForgetPassword, setForgetPassword] = useState(false)

    return (
        <aside>
            <figure>
                <a href="/">
                    <img src="/wp-content/themes/boerderijvakanties/img/custom/logo.png"
                        width="220"
                        alt="Boerderijvakanties logo"
                    />
                </a>
            </figure>
            {isForgetPassword ? (
                <ForgetPasswordForm setForgetPassword={setForgetPassword} />
            ) : (
                <LoginForm setForgetPassword={setForgetPassword} />
            )}
            <div className="copy">© 2021 Boerderijvakanties</div>
        </aside>
    )
}

document.addEventListener('DOMContentLoaded', () => {
    const entry = document.querySelector('#login');
    ReactDOM.render(<AuthenticationForm />, entry);
});
