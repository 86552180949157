import DOMPurify from "dompurify";
import {Button} from "@mantine/core";
import React, {useState} from "react";

const ForgetPasswordForm = ({setForgetPassword}) => {
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [message, setMessage] = useState(null)

    const submitLogin = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append('action', 'send_forget_password_email');
        formData.append('user_email', email);

        fetch(`/wp-admin/admin-post.php`, {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                if (data.success && data.email_send) {
                    return setSuccess(true);
                } else {
                    setError(true);
                    setMessage(data.message)
                }
            })
            .catch((e) => {
                console.log(e)
                setError(true);
            })
            .finally(() => setLoading(false));
    }

    return (
        <form onSubmit={submitLogin}>
            <input type="hidden" name="action" value="frontend_authentication"/>
            <p className="mb-1">
                <strong>Inloggegevens vergeten? Geen probleem.</strong><br/>
                <i>
                    Vul je e-mailadres in. Na een paar minuten krijg je een e-mail. Daarin staat hoe je een
                    nieuw wachtwoord instelt.
                </i>
            </p>

            {success && <p className="text-success">Er is een mail met daarin een reset link verstuurd naar {email}.</p>}

            {error && (
                <p className="mb-1 text-danger">
                    {message ? (
                        <div className="content"
                             dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(message)}}/>
                    ) : 'Er is iets fout gegaan aan onze kant, probeer het later nog eens!'}
                </p>
            )}
            <div className="form-group">
                <label>Email</label>
                <input required type="text" className="form-control" name="log" id="email" value={email}
                       onChange={(e) => setEmail(e.target.value)}/>
                <i className="icon_mail_alt"/>
            </div>
            <div className="clearfix add_bottom_30">
                <div className="float-right mt-1">
                    <a id="forgot btn-link" href="#login" onClick={() => setForgetPassword(false)}>Inloggen?</a>
                </div>
            </div>

            <Button type={"submit"} color="orange" radius="xl" size="md"
                    classNames={{root: 'btn_1 rounded full-width'}}
                    loading={loading}>
                {loading ? 'Wachtwoord link versturen...' : 'Email versturen'}
            </Button>

            <div className="text-center add_top_10">
                Uw accommodatie ook op onze website? <strong><a href="/accommodatie-aanmelden/">Registreer
                hier!</a></strong>
            </div>
        </form>
    )
}

export default ForgetPasswordForm