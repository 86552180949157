import DOMPurify from "dompurify";
import {Button} from "@mantine/core";
import React, {useState} from "react";
import {useLocalStorageValue} from "@mantine/hooks";

const LoginForm = ({setForgetPassword}) => {
    const [, setJWTToken] = useLocalStorageValue({key: 'jwt-token', defaultValue: null});
    const [, setUserID] = useLocalStorageValue({key: 'user-id', defaultValue: null});
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [message, setMessage] = useState(null)

    const submitLogin = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append('action', 'frontend_authentication');
        formData.append('log', username);
        formData.append('pwd', password);
        formData.append('rememberme', true);

        fetch(`/wp-admin/admin-post.php`, {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    if (data.token) setJWTToken(data.token);
                    if (data.ID) setUserID(data.ID);
                    window.location.replace("/dashboard");
                } else {
                    console.log(data);
                    setError(true);
                    setMessage(data.message);
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
                setError(true);
                setLoading(false);
            })
    }


    return (
        <form method="post" onSubmit={submitLogin}>
            <input type="hidden" name="action" value="frontend_authentication"/>
            <p className="mb-1">
                Welkom terug, log hier in:
            </p>
            {error && (
                <p className="mb-1 text-danger">
                    {message ? (
                        <div className="content"
                             dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(message)}}/>
                    ) : 'Er is iets fout gegaan aan onze kant, probeer het later nog eens!'}
                </p>
            )}
            <div className="form-group">
                <label>Email</label>
                <input required type="text" className="form-control" name="log" id="email" value={username}
                       onChange={(e) => setUsername(e.target.value)}/>
                <i className="icon_mail_alt"/>
            </div>
            <div className="form-group">
                <label>Wachtwoord</label>
                <input required type="password" className="form-control" name="pwd" id="password"
                       value={password}
                       onChange={(e) => setPassword(e.target.value)}/>
                <i className="icon_lock_alt"/>
            </div>
            <div className="clearfix add_bottom_30">
                <div className="float-right mt-1">
                    <a id="forgot btn-link" href="#forget-password" onClick={() => setForgetPassword(true)}>Wachtwoord
                        vergeten?</a>
                </div>
            </div>

            <Button type={"submit"} color="orange" radius="xl" size="md"
                    classNames={{root: 'btn_1 rounded full-width'}}
                    loading={loading}>
                {loading ? 'Inloggen...' : 'Inloggen'}
            </Button>

            <div className="text-center add_top_10">
                Uw accommodatie ook op onze website? <strong><a href="/accommodatie-aanmelden/">Registreer
                hier!</a></strong>
            </div>
        </form>
    )
}

export default LoginForm